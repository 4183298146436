import './404.scss';

import React from 'react';

import ContentWrapper from 'components/core/ContentWrapper';
import SecondaryHeading from 'components/core/SecondaryHeading';

const NotFoundPage: React.FC = () => {
  return (
    <div>
      <ContentWrapper>
        <SecondaryHeading>
          Sorry, we couldn&rsquo;t find what you were looking for.
        </SecondaryHeading>
        <p>
          Click here to go <a href="/">home</a>.
        </p>
      </ContentWrapper>
    </div>
  );
};

export default NotFoundPage;
