import './SecondaryHeading.scss';

import React from 'react';

interface Props {
  children: React.ReactNode;
}

const SecondaryHeading: React.FC<Props> = (props) => {
  const { children } = props;

  return <div className="SecondaryHeading">{children}</div>;
};

export default SecondaryHeading;
