import './ContentWrapper.scss';

import React from 'react';
import classnames from 'classnames';

interface Props {
  className?: string;
  children: React.ReactNode;
}

const ContentWrapper: React.FC<Props> = (props) => {
  const { className, children } = props;

  return (
    <div className={classnames('ContentWrapper', className)}>{children}</div>
  );
};

export default ContentWrapper;
